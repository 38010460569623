@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 4px;
  margin-bottom: 0;
  color: #FF0000;
  overflow: hidden;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  

  @media (--viewportMedium) {
    margin-top: 4px;
  }
}
