@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;

  flex-shrink: 0;
  padding: 0;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 0 48px;
  }

  @media (--viewportLarge) {
    padding: 0 100px;
  }
}
